/**
 * GEOLOCATION
 * ===========================================
 *
 */
$(document).ready(function() {

    'use strict';

    var geoCallBackTriggered    = false,
        lat                     = null,
        lng                     = null,
        postal                  = null,
        token                   = $('meta[name=_token]').attr('content'),
        userPostalCode          = $('meta[name=userPostalCode]').attr('content'),
        refreshPage             = false;

    function init(){

        return false; // Turn Off GeoLoc

        // Only if user is not already geo located...
        if( ! userPostalCode || userPostalCode === 'null' ){

            geoLocateUser();

        }else{

            // We have already geolocated the uer...
            geoCallBackTriggered = true;
            ajaxSaveUserPostalCode();

            refreshPage = false;

        }

        // See if geo callback as been triggerd
        // This is due to firefox 'not now' option
        // that does not trigger any geo callback
        setTimeout(function(){

          if( ! geoCallBackTriggered ){
            ajaxSaveUserPostalCode();
          }

        }, 6500);

    }

    function geoLocateUser(){

        // Check if geolocalisation is possible...
        if (navigator.geolocation) {

          // Geolocate the user...
          navigator.geolocation.getCurrentPosition(geoSuccess, geoError);

        } else {

          geoError('not supported');

        }

    }

    function geoSuccess(position) {

        geoCallBackTriggered = true;

        lat = position.coords.latitude;
        lng = position.coords.longitude;
        // var acc = position.coords.accuracy;

        ajaxSaveUserPostalCode();

        refreshPage = true;


    }

    function geoError(msg) {

        geoCallBackTriggered = true;
        ajaxSaveUserPostalCode();
        refreshPage = false;

    }

    function ajaxSaveUserPostalCode(){

        $.ajax({
          type: 'POST',
          url: '/fr/saveUserPostalCode',
          data: "q=1&lat="+lat+"&lng="+lng+"&postal="+postal+"&_token="+token,
          success: function(data){

            if( refreshPage ){
              if( data === 'isToronto' ){
                window.location = window.location.href.replace('/fr', '/en');
              }else{
                location.reload();
              }
            }

          }
        });

    }

    init();

});
