// Namespacing

window.Deckperfo = window.Deckperfo  || {};



/**
 * Avoid `console` errors in browsers that lack a console.
 * ===========================================
 *
 */
;(function() {
    'use strict';
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());


/**
 * SCRIPT GoTo();
 * ===========================================
 *
 */
$(document).ready(function() {

  'use strict';

    function init(){

        initEvents();

    }

    function initEvents(){

        /*goTo*/
        $( '[data-goto]' ).on( 'click', function(event){

            event.preventDefault();

            var nextSection = $( this ).attr( 'data-goto' );

            $( 'html, body' ).animate({
                scrollTop: ($( '#'+nextSection ).offset().top) - ($('#main-nav').outerHeight()) + 1
            }, 855);

        });

    }

  init();

});




// BlocMapContact2 Module

(function ($) {

    "use strict";

    var self,
        map;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocMapContact2() {

        self = this;
        map = null;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocMapContact2.prototype.init = function () {

        this.addEventHandler();

        var mapHolder = $('#map');

        var app_adress =  $('#bloc-map .adresses .bloc-adresse').eq(0).data('marker-adresse');
        var marker_Lat1 = $('#bloc-map .adresses .bloc-adresse').eq(0).data('marker-lat');
        var marker_Lng1 =  $('#bloc-map .adresses .bloc-adresse').eq(0).data('marker-lng');

        var app_adress_2 =  $('#bloc-map .adresses .bloc-adresse').eq(1).data('marker-adresse');
        var marker_Lat2 = $('#bloc-map .adresses .bloc-adresse').eq(1).data('marker-lat');
        var marker_Lng2 =  $('#bloc-map .adresses .bloc-adresse').eq(1).data('marker-lng');

        var currentLat = $('#bloc-map .adresses .bloc-adresse.active').data('marker-lat');
        var currentLng = $('#bloc-map .adresses .bloc-adresse.active').data('marker-lng');
        console.log(currentLat + ' / ' + currentLng);
        var mapSettings = {
            linkToMap : '#',
            pinImage : '/assets/images/google-marker.png',
            pinImage2 : '/assets/images/google-marker.png',
            imageTouch : '/assets/images/google-marker.png'
        };

        var zoomLvl = 12;

        if( $(window).width() <= 699 ){
            zoomLvl = 11;
        }

        if (mapHolder.length) {

            //MAP
            var initialize = function() {
                var mapOptions = {
                    zoom: zoomLvl,
                    scrollwheel: false,
                    overviewMapControl: false,
                    panControl: false,
                    draggable : false,
                    disableDefaultUI: true,
                    rotateControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    center: new google.maps.LatLng( currentLat, currentLng ),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
                };

                map = new google.maps.Map(document.getElementById('map'),mapOptions);

                var myLatLng = new google.maps.LatLng( marker_Lat1, marker_Lng1 );
                var myLatLng2 = new google.maps.LatLng( marker_Lat2, marker_Lng2 );

                var hoffmanMarker = new google.maps.Marker({
                  position: myLatLng,
                  map: map,
                  icon: mapSettings.pinImage
                });

                var hoffmanMarker2 = new google.maps.Marker({
                  position: myLatLng2,
                  map: map,
                  icon: mapSettings.pinImage2
                });

                google.maps.event.addListener(hoffmanMarker, 'click', function () {
                  window.open(mapSettings.linkToMap, '_blank');
                });

                google.maps.event.addListenerOnce(map, 'idle', function(){
                    // do something only the first time the map is loaded
                    // $('#bloc-map .adresses .bloc-adresse.active').trigger('click');
                    map.setCenter(new google.maps.LatLng(currentLat, currentLng));
                });

                //Retrive the center location
                // google.maps.event.addListener(map, "center_changed", function() {
                //     console.log( map.getCenter().toUrlValue() );
                // });

            };

            google.maps.event.addDomListener(window, 'load', initialize);

        }

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    BlocMapContact2.prototype.addEventHandler = function () {

        self = this;

        $('.adresses .bloc-adresse').on('click', function(event) {

            event.preventDefault();

            var myIndex = $(this).index(),
                myAdress = $(this).data('marker-adresse'),
                myLat = $(this).data('marker-lat'),
                myLng = $(this).data('marker-lng');

            $('.adresses .bloc-adresse').removeClass('active');
            $(this).addClass('active');

            map.setCenter(new google.maps.LatLng(myLat, myLng));

            var windowWidth = self.viewport().width;
            if( windowWidth < 768 ){

                var navHeight = $('#main-nav').outerHeight();

                $( 'html, body' ).animate({
                    scrollTop: $( '#bloc-map' ).offset().top - navHeight
                }, 855);

            }

        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    BlocMapContact2.prototype.viewport = function () {

        self = this;

         var e = window, a = 'inner';
          if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
          }
          return { width : e[a + 'Width'], height : e[a + 'Height'] };

    };

    this.BlocMapContact2 = new BlocMapContact2().init();

}.bind(Deckperfo, jQuery)());




/**
 * Profil Equipe
 *   -Follow on scroll
 *
 * ===========================================
 */
$(document).ready(function() {

  'use strict';

  function init(){
    updateSidebarPosition();
  }

  $(window).scroll(function(){
    updateSidebarPosition();
  });

  function updateSidebarPosition() {

    if( ! $('#profil-equipe').length ){ return false; }

    var articlesWrapper       = $('#profil-equipe'),
        aside                 = $('.visual .profil'),
        articlesWrapperTop    = articlesWrapper.offset().top,
        articlesWrapperHeight = articlesWrapper.outerHeight(),
        scrollTop             = $(window).scrollTop(),
        windowHeight          = $(window).height();

    if( scrollTop < articlesWrapperTop) {

        // Over
        // aside.css( 'top', 0 + 'px' );

    } else if( scrollTop >= articlesWrapperTop && scrollTop < articlesWrapperTop + articlesWrapperHeight - windowHeight ) {

        var newTop = scrollTop - articlesWrapperTop;

        // aside.css( 'top', newTop + 'px' );

    } else if( scrollTop >= articlesWrapperTop && scrollTop >= articlesWrapperTop + articlesWrapperHeight - windowHeight ) {

        // Under

    }

  }

  init();

});




// ViewSwitcher Module

(function ($) {

    "use strict";

    var self,
        viewsModule,
        viewsNav,
        viewContents;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function ViewSwitcher() {

        self = this;
        viewsModule = '.views';
        viewsNav = '.switchers a';
        viewContents = '.view';

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    ViewSwitcher.prototype.init = function () {

        self = this;

        self.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    ViewSwitcher.prototype.addEventHandler = function () {

        self = this;

        $( viewsModule ).on('click', viewsNav, function(event) {

            event.preventDefault();

            var selectedView = $(this).data('switch-view');

            $(viewsNav).removeClass('active');
            $(this).addClass('active');

            $( viewContents ).removeClass('active');
            $( viewContents + '[data-view="'+selectedView+'"]' ).addClass('active');

        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    this.ViewSwitcher = new ViewSwitcher().init();

}.bind(Deckperfo, jQuery)());




// Burger nav menu

(function ($) {

    "use strict";

    var self,
        burger,
        nav,
        overlay;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BurgerNav() {

        self    = this;
        burger  = '#main-nav .menu';
        nav     = '#nav-links';
        overlay = '#nav-links-overlay';

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BurgerNav.prototype.init = function () {

        self = this;

        self.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    BurgerNav.prototype.addEventHandler = function () {

        self = this;

        $(burger).on('click', function(event) {
            event.preventDefault();
            $(nav).toggleClass('active');
            $(overlay).toggleClass('active');
            $(burger).toggleClass('active');
        });

        $(nav).on('click',  function(event) {
            if($(this).hasClass('active')){
                if($('body').hasClass('home')){ event.preventDefault(); }
                $(nav).removeClass('active');
                $(overlay).removeClass('active');
                $(burger).removeClass('active');
            }
        });

        $(overlay).on('click', function(event) {
            event.preventDefault();
            $(nav).removeClass('active');
            $(overlay).removeClass('active');
            $(burger).removeClass('active');
        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    this.BurgerNav = new BurgerNav().init();

}.bind(Deckperfo, jQuery)());






/**
 * Input File Delegate
 * ===========================================
 *
 */
$(document).ready(function() {

    'use strict';

    function init(){

        initEvents();

    }

    function initEvents(){

        $('.main-form').on('change', 'input[type="file"]', function(event) {
            event.preventDefault();
            $(this).parents('.file').find('.input-file-delegate').text( $(this).val() );
        });

    }

  init();

});





/**
 * Carrieres
 *   - Form aside follow
 *
 * ===========================================
 */
$(document).ready(function() {

  'use strict';

  function init(){
    updateSidebarPosition();
  }

  $(window).scroll(function(){
    updateSidebarPosition();
  });

  function updateSidebarPosition() {

    if( ! $('#info-carrieres .application-form').length ){
        return false;
    }

    var articlesWrapper       = $('#info-carrieres'),
        aside                 = $('#info-carrieres .application-form'),
        articlesWrapperTop    = articlesWrapper.offset().top,
        articlesWrapperHeight = articlesWrapper.outerHeight(),
        scrollTop             = $(window).scrollTop(),
        windowHeight          = $(window).height(),
        windowWidth           = $(window).width(),

        waitForIt             = null;

    if( windowHeight < 770 ){ return false; }
    if( windowWidth <= 1024 ){ return false; }

    if( scrollTop < articlesWrapperTop) {

      aside.removeClass('fixed').attr('style', '');

    } else if( scrollTop >= articlesWrapperTop && scrollTop < articlesWrapperTop + articlesWrapperHeight - windowHeight ) {

      aside.addClass('fixed');

      var newTop = scrollTop - articlesWrapperTop;

      clearTimeout( waitForIt );
      waitForIt = null;

      //waitForIt = setTimeout(function(){
        aside.css( 'top', (newTop + 125) + 'px' );
      //}, 555);

    } else {

      /*var articlePaddingTop = Number(articles.eq(1).css('padding-top').replace('px', ''));

      if( aside.hasClass('fixed') ){
        aside.removeClass('fixed').css('top', articlesWrapperHeight + articlePaddingTop - windowHeight + 'px');
      }*/

    }

  }

  init();

});

